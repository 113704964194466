<template>
  <div class="px-[20px] flex items-center relative" :style="{ height: `${windowHeight-240}px` }">
    <div class="w-full walkie-talkie rounded-lg bg-ems-tag1 h-[98%]">
      <TopChat :is-show-action="false" :isShowConnect="true"/>
      <div class="h-[0.5px] w-full bg-ems-gray700"></div>
      <div class="flex px-[16px] mt-[23px] space-x-[13px] relative">
        <img alt="wifi" src="@/static/img/icon/wifi-connect.svg" />
        <img
          alt="volumn"
          src="@/static/img/icon/volumn.svg"
          class="cursor-pointer"
          @click="openVolumnModal"
        />
        <div
          v-if="isShowVolumn"
          class="h-[138px] w-[230px] bg-ems-gray800 rounded-lg absolute right-[45px]"
        >
          <img
            alt="close"
            src="@/static/img/icon/close-volumn.svg"
            class="absolute right-[4px] top-[4px] cursor-pointer"
            @click="handleClose"
          />
          <CustomSlider
            :icon="require('@/static/img/icon/volumn-slider.svg')"
            :title="'Âm lượng'"
          />
          <CustomSlider
            :icon="require('@/static/img/icon/mic-slider.svg')"
            :title="'Voice'"
          />
        </div>
      </div>
      <Voice/>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
} from 'vue';
import Item from './components/item.vue';
import Voice from './components/voice.vue';
import { useStore } from 'vuex';
import TopChat from './components/top-chat.vue';
import CustomSlider from './components/custom-slider.vue';
import ClipButton from '@/components/buttons/ClipButton.vue';
import {useWindowHeight} from '@/util/common-utils';
import VueTypes from 'vue-types';
const { state, dispatch } = useStore();
const isShowVolumn = ref(false);
const { windowHeight } = useWindowHeight();
const props = defineProps({
  activeKey: VueTypes.string.def(''),
});
const openVolumnModal = () => {
  isShowVolumn.value = true;
};
const handleClose = () => {
  isShowVolumn.value = false;
};
</script>
<style lang="scss">
.walkie-talkie .ant-input-affix-wrapper > input.ant-input {
  background: #2B2A3A;
  color: #FFFFFF;
}
.walkie-talkie .ant-input-affix-wrapper > input.ant-input::placeholder {
  color: #F7F0F7;
}
</style>
